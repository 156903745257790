import useFadeIn from "../hooks/useFadeIn";

function Card({video, title, text, timeout}) {

    const {fadeClass, fadeRef} = useFadeIn(timeout);

    return <div className={'col-md-4 p-5 ' + fadeClass} ref={fadeRef}>
        <video className="w-100" src={video} muted autoPlay loop/>
        <h3 className="text-center mb-4 mt-4">{title}</h3>
        <div className="text-center">
            {text}
        </div>
    </div>

}

export default Card;