import './App.css';
import React, {Fragment} from "react";
import Feature from "./components/Feature";
import Card from "./components/Card";
import useFadeIn from "./hooks/useFadeIn";
import Social from "./components/Social";
import Trailer from "./components/Trailer";

const features = [
    {
        title: 'Procedural roads',
        text: <span>
            The whole road network has been reinvented. No longer you have to select from a predefined
            list of roads, you can easily create any kind you like by yourself.
            <br/><br/>
            Using the cross-section tool, add, edit, or remove surfaces from the road, modify their width,
            set direction, prop placement, speed limits, vehicle restrictions, paint horizontal lines, and so on.
        </span>,
        background: 'img/background_road.jpg',
        video: 'vid/road.mp4',
    },
    {
        title: 'Modular intersections',
        text: <span>
            In a place where roads meet, let the algorithm do the work for you or design the intersection by yourself.
            You have a large number of tools at your disposal:
            <br/><br/>
            <ul>
                <li>place individual lanes, walkways, railways, and surfaces like pavements, lawns, or even curbs</li>
                <li>create roundabouts, restrict directions, specify turning lanes</li>
                <li>place crosswalks, bike, tram, and rail crossings</li>
                <li>place traffic lights, set up their phases</li>
                <li>designate rights of way</li>
                <li>create islands for pedestrians</li>
                <li>paint horizontal lines</li>
                <li>finish with street lamps, trees, railings, noise-cancelling barriers, etc.</li>
            </ul>
        </span>,
        background: 'img/background_intersection.jpg',
        video: 'vid/intersection.mp4',
    },
    {
        title: 'Advanced simulation',
        text: <span>
            When you're done building, start the simulation. Cars, trucks, buses, trams, trains,
            pedestrians, and cyclists will enter the map based on the map spawn settings.
            Advanced multithreaded AI will comply with all rules of the road, like giving the right of way,
            changing lanes, respecting speed limits, light signalling, and so on.
            <br/><br/>
            View stats based on the simulation result like throughput, average speed, emissions, etc.
        </span>,
        background: 'img/background_simulation.jpg',
        video: 'vid/simulation.mp4',
    },
]

const cards = [
    {
        title: 'Sandbox or challenge',
        text: <span>
            Progress through the challenge mode and design increasingly complex intersections based on locations
            from around the world or hand-crafted by our team, all the while keeping the budget under control,
            the throughput high, and the nearby buildings still standing. Or you can enjoy the sandbox mode
            where there'll be no limits to your imagination.
        </span>,
        video: 'vid/bridges.mp4',
    },
    {
        title: 'Map import',
        text: <span>
            Not feeling creative? Try the map import tool to generate a map from the real world
            just by entering any coordinates! Includes minimalistic buildings, road lines, and satellite overlays.
            <br/><br/>
            Do you have a driver's license? Now you'll finally have the opportunity to prove to everyone
            that you would do a better job designing that one intersection in your city you keep complaining about.
        </span>,
        video: 'vid/import.mp4',
    },
    {
        title: 'The team & the tech',
        text: <span>
            Created largely by a lone developer as a passion project, Junxions aims to fill some of the void
            left by other city builders. In late 2023 a few people joined the project part-time,
            however only the original dev remains working full-time.
            <br/><br/>
            Developed using the latest Unity DOTS package with multithreading via ECS,
            burst compiled and optimized for a sequel as a fully-fledged city builder,
            which remains our ultimate goal.
        </span>,
        video: 'vid/pedestrians.mp4',
    },
];

function App() {

    const {fadeClass, fadeRef} = useFadeIn();

    return (
        <>

            <div id="menu">
                <div className="container-fluid d-flex align-items-center justify-content-end h-100 pe-5">
                    <Social/>
                </div>
            </div>

            <div id="welcome">
                <div style={{background: '#00000099'}}>

                    <div style={{height: '15vh'}}/>
                    <div className="container pt-5 mb-5">

                        <div className="row justify-content-center">
                            <div className="col-6">
                                <img src="/img/logo.png" className="img-fluid"/>
                            </div>
                        </div>

                        <div className="mt-5 text-center">
                            An upcoming indie <b>sandbox simulation game for PC</b>.<br/>
                            <br/>
                            Building <b>roads and intersections</b> completely reimagined,<br/>
                            with traffic simulated by <b>powerful AI</b>.
                        </div>

                        <div className="row mt-5">
                            <div className="offset-md-1 col-md-5 text-center">
                                <h3 className="text-uppercase text-center call-to-action">
                                    Wishlist now on
                                </h3>
                                <div className="mt-5 text-center">
                                    <a href="https://store.steampowered.com/app/1447250/Junxions/">
                                        <img src="/img/steam.png" className="img-fluid" style={{maxWidth: 200}}
                                             alt="Junxions on Steam"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-5 text-center">
                                <div className="mt-5 d-block d-md-none"/>
                                <h3 className="text-uppercase text-center call-to-action">
                                    Join the Community
                                </h3>
                                <div className="mt-5 text-center">
                                    <a href="https://discord.gg/64XQQB874E">
                                        <img src="/img/discord_big.png" className="img-fluid" style={{maxWidth: 200}}
                                             alt="Junxions Discord server"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{height: '15vh'}}/>
                </div>
            </div>

            <Trailer/>

            {features.map((feature, i) =>
                <Feature key={i} background={feature.background} image={feature.image}
                         left={i % 2 === 0} right={i % 2 === 1}
                         title={feature.title} text={feature.text} video={feature.video}
                />
            )}

            <div className="container-fluid p-md-5" style={{background: 'black'}}>
                <h3 className="p-5 text-uppercase text-center">More details</h3>
                <div className="row">
                    {cards.map((card, i) =>
                        <Card key={i} timeout={i % 3 * 0.3}
                              video={card.video} title={card.title} text={card.text}/>
                    )}
                </div>
                <div className="p-5"/>
            </div>

            <div id="welcome">
                <div style={{background: '#00000099'}}>
                    <div style={{height: '20vh'}}/>
                    <div className={'text-center ' + fadeClass}>
                        <h4 className="mb-5" ref={fadeRef}>Join the community</h4>
                        <Social/>
                    </div>
                    <div style={{height: '20vh'}}/>
                </div>
            </div>

            <div className="container-fluid p-5 text-center" style={{background: 'black'}}>
                <img src="/img/fpu.png" className="img-fluid"/>
                <div className="mb-5"/>
                &copy;2024 <a href="https://cyberstorm.games">CyberStorm</a>
                <br/>
                <br/>
                <small style={{color: '#aaaaaa'}} className="text-sm">
                    All images, videos, and audio are from an alpha version of the game. Subject to change.
                </small>
            </div>

        </>
    );
}

export default App;
