import React from "react";

function useFadeIn(timeout) {

    const [isVisible, setVisible] = React.useState(false);
    const fadeRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (!timeout) {
                        setVisible(entry.isIntersecting);
                    } else {
                        setTimeout(() => {
                            setVisible(entry.isIntersecting);
                        }, timeout * 1000);
                    }
                }
            });
        });
        observer.observe(fadeRef.current);
        return () => observer.unobserve(fadeRef.current);
    }, []);

    return {isVisible, fadeClass: `fade-in-section ${isVisible ? 'is-visible' : ''}`, fadeRef};

}

export default useFadeIn;