import useFadeIn from "../hooks/useFadeIn";

function Feature({background, image, video, title, text, right, left}) {

    const {fadeClass, fadeRef} = useFadeIn();
    const isRight = !!right

    return <div className={"background "} style={{backgroundImage: 'url(' + background + ')'}} ref={fadeRef}>
        <div className="tint p-md-5">
            <div className="container-fluid d-none d-md-block">
                <div className="row">
                    {!isRight
                        ?
                        <div className="col-6 p-5">
                            <ImageOrVideo image={image} video={video} fade={fadeClass}/>
                        </div>
                        : null
                    }
                    <div className={'col-6 p-5 ' + fadeClass}>
                        <h3 className="mb-4">{title}</h3>
                        {text}
                    </div>
                    {isRight &&
                        <div className="col-6 p-5">
                            <ImageOrVideo image={image} video={video} fade={fadeClass}/>
                        </div>
                    }
                </div>
            </div>
            <div className="container-fluid d-md-none">
                <div className="row">
                    <div className="col-12 p-5">
                        <ImageOrVideo image={image} video={video} fade={fadeClass}/>
                    </div>
                    <div className={'col-12 p-5 ' + fadeClass}>
                        <h3 className="mb-4">{title}</h3>
                        {text}
                    </div>
                </div>
            </div>
        </div>
    </div>

}

const ImageOrVideo = ({image, video, fade}) => {
    return video
        ? <div className={fade}>
            <video className="w-100" src={video} muted autoPlay loop/>
        </div>
        : <img src={image} className={'img-fluid ' + fade}/>
}

export default Feature;