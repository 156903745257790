import React from "react";
import useFadeIn from "../hooks/useFadeIn";

function Trailer() {

    const {fadeClass, fadeRef} = useFadeIn();

    return <div style={{width: '100%', aspectRatio: '16 / 9', marginBottom: -7, backgroundColor: 'black'}}>
        <iframe style={{width: '80%', height: '80%', margin: '10%'}}
                ref={fadeRef} className={fadeClass}
                src="https://www.youtube.com/embed/Zy6XdcICMIo?si=cC76yHu9Fm_4cwcf"
                title="Junxions trailer YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    </div>

}

export default Trailer